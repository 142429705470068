import * as React from 'react'
import loadable from '@loadable/component'

const NotQuotedEmailTemplate = loadable(() => import('/src/templates/email/NotQuoted'))

const EmailPage = () => {
    const content = {
        figures: 'Did you know you could potentially get £280,000 of cover for just £5.15 per month?*',
        disclaimer: '*Prices based on an 18-year-old, non-smoker in perfect health over 20 years.'
    }

    return <NotQuotedEmailTemplate content={content} />
}

export default EmailPage
